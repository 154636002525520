@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
.cv-data {
  font-size: 0.85em;
  display: flex;
  margin-bottom: 5px; }
  .cv-data:last-child {
    margin-bottom: 0px; }
  .cv-data .divider {
    border-left: 1px solid #969696; }
  .cv-data-years {
    flex: 20% 1;
    text-align: right;
    padding-right: 8px;
    font-weight: 400; }
  .cv-data-info {
    flex: 85% 1;
    padding-left: 8px; }

@media (max-width: 769px) {
  .cv-data-years div span {
    display: block; } }

@media (min-width: 415px) {
  .small-phone {
    display: none; } }

@media (max-width: 415px) {
  .cv-data {
    flex-direction: column; }
    .cv-data .divider {
      border-left: inherit; }
    .cv-data-years {
      text-align: inherit;
      font-weight: 400; }
      .cv-data-years div {
        display: inline; }
        .cv-data-years div:last-child::before {
          content: ' - '; }
        .cv-data-years div span {
          display: inline; }
    .cv-data-info {
      padding-left: inherit; }
  .no-small-phone {
    display: none; } }

.download-footer {
  margin: 30px 0 0 0;
  text-align: center;
  font-size: 0.85em;
  color: #2f2f2f; }
  .download-footer p {
    margin: 0; }
  .download-footer-icon {
    color: #e25555; }
  .download-footer-link {
    flex: 1 1;
    text-align: right;
    font-size: 1.5em;
    margin-bottom: 50px;
    padding-right: 10px; }

@media (min-width: 769px) {
  .download-footer-link {
    padding-right: 0; } }

.references-list {
  font-size: 0.85em;
  margin-left: 20px; }
  .references-list--empty {
    font-weight: 400; }

.references-item {
  margin-bottom: 10px; }
  .references-item--name {
    font-weight: 400; }
  .references-item--title {
    font-style: italic; }
  .references-item--composed {
    display: flex; }
  .references-item--icon {
    text-align: center;
    flex: 15% 1; }
  .references-item--value {
    flex: 85% 1; }

.experiences .experience-info {
  font-weight: 400; }
  .experiences .experience-info--company, .experiences .experience-info--country {
    margin-left: 5px; }
  .experiences .experience-info--company {
    text-transform: uppercase; }

.experiences .experience-description {
  text-align: justify;
  margin-bottom: 3px; }

.experiences .experience-tasks--elem {
  display: flex; }
  .experiences .experience-tasks--elem .bullet {
    flex: 5% 1;
    font-size: 0.75em;
    padding: 0.3em 3px 0 0;
    text-align: right; }
  .experiences .experience-tasks--elem .value {
    flex: 95% 1;
    text-align: justify; }

.experiences .experience-technologies {
  margin-bottom: 7px; }
  .experiences .experience-technologies-key {
    margin-top: 3px;
    font-size: 0.75em;
    border-radius: .25em;
    border: 1px solid #969696;
    padding: 2px;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 4px; }

.languages .language {
  display: flex;
  font-weight: 400;
  font-size: 0.85em; }
  .languages .language-name {
    padding-right: 10px;
    flex: 20% 1;
    text-align: right; }
  .languages .language-title {
    padding-right: 10px;
    text-align: center;
    flex: 30% 1; }
  .languages .language-level {
    flex: 40% 1;
    color: #4f8e96; }
    .languages .language-level--point {
      margin-right: 5px; }
      .languages .language-level--point-off {
        color: #dedede; }

@media (max-width: 769px) {
  .languages .language-name {
    flex: 30% 1; }
  .languages .language-title {
    flex: 30% 1; }
  .languages .language-level {
    flex: 30% 1; } }

@media (max-width: 415px) {
  .languages .language-name {
    flex: 25% 1; }
  .languages .language-title {
    flex: 25% 1; }
  .languages .language-level {
    flex: 50% 1; } }

.education-data-title--name {
  font-weight: 400; }

.education-university {
  font-size: 0.9em;
  font-style: italic; }

.education-major-subjects, .education-thesis {
  font-size: 0.8em;
  text-align: justify; }
  .education-major-subjects span, .education-thesis span {
    font-style: italic; }
  .education-major-subjects ul, .education-thesis ul {
    -webkit-padding-start: 5px;
            padding-inline-start: 5px; }

.section--header {
  color: #24717c;
  font-size: 1.5em;
  padding-bottom: 5px;
  border-bottom: 1px solid #969696;
  margin-bottom: 12px; }

.section--title {
  font-weight: 300;
  margin-left: 10px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-before: 0px;
          margin-block-start: 0px; }
  .section--title::first-letter {
    font-size: 1.4em; }

@media (min-width: 569px) {
  .section {
    padding: 0 25px; } }

.skills .skill-line {
  font-size: 0.85em; }

.skills .skill-title {
  font-weight: 400; }

.skills .skill-list ul {
  padding: 0; }
  .skills .skill-list ul:before {
    content: "- "; }
  .skills .skill-list ul li strong {
    font-weight: 400; }
  .skills .skill-list ul li:after {
    content: ", "; }
  .skills .skill-list ul li:last-child:after {
    content: ""; }

@media (min-width: 415px) {
  .skills .skill-line {
    display: flex; }
  .skills .skill-title {
    flex: 30% 1;
    padding-right: 7px;
    text-align: right; }
  .skills .skill-list {
    flex: 70% 1; }
    .skills .skill-list:before {
      content: ""; }
    .skills .skill-list ul {
      padding: 0; }
      .skills .skill-list ul:before {
        content: ""; } }

@media (min-width: 769px) {
  .skills .skill-title {
    flex: 25% 1; } }

.header {
  margin: 40px 0 0 0;
  font-size: 0.75em;
  margin-bottom: 5px; }
  .header svg {
    color: #24717c; }
  .header-photo-container {
    text-align: center;
    margin-bottom: 20px; }
    .header-photo-container--picture {
      border-radius: 50%;
      height: 8em;
      width: 8em; }
  .header-info {
    order: 0; }
    .header-info p {
      -webkit-margin-after: 0px;
              margin-block-end: 0px;
      -webkit-margin-before: 0px;
              margin-block-start: 0px; }
    .header-info .header-fullname {
      text-align: center;
      font-size: 1.7em;
      color: #24717c; }
      .header-info .header-fullname p {
        display: inline-block;
        margin-top: 3px; }
        .header-info .header-fullname p:first-letter {
          font-size: 1.3em; }
      .header-info .header-fullname--name {
        font-weight: 300; }
      .header-info .header-fullname--surname {
        text-transform: uppercase;
        margin-left: 0.3em;
        font-weight: 400; }
    .header-info .header-position {
      text-align: center;
      color: #24717c;
      font-size: 1.3em;
      font-weight: 400;
      margin: 5px 0 3px 0; }
    .header-info .header-contact--link a {
      margin-left: 5px; }
    .header-info .header-city span {
      margin-left: 5px; }
    .header-info .header-extra-info span {
      margin-left: 5px; }

@media (min-width: 569px) {
  .header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 20px; }
    .header-photo-container {
      order: 1; }
      .header-photo-container--picture {
        border-radius: 50%;
        height: 10em;
        width: 10em; }
    .header-info {
      order: 0; }
      .header-info p {
        display: inline-block;
        margin-top: 3px; }
      .header-info .add-space {
        margin-left: 10px; }
      .header-info .header-fullname {
        text-align: left; }
      .header-info .header-position {
        text-align: left; } }

.menu {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase; }
  .menu li {
    padding: 0 15px 5px 15px;
    margin: 0 15px; }
    .menu li a {
      font-size: 1.2em;
      color: #24717c;
      cursor: pointer; }
  .menu--item-selected {
    border-bottom: 3px solid #24717c; }

.cv {
  font-size: 1rem;
  width: 100%;
  background: #fff;
  justify-content: flex-end;
  padding: 5px; }

@media (min-width: 769px) {
  .cv {
    margin: 40px 0;
    padding: 20px;
    width: 769px;
    box-shadow: 0px 0px 50px -10px #101010; }
    .cv .section-wrapper {
      display: flex; }
      .cv .section-wrapper > div {
        flex: 50% 1; } }

html, body {
  height: 100%; }

body {
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #24717c; }

a:link, a:active, a:visited, a:hover {
  text-decoration: none;
  color: black; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul {
  display: inline;
  list-style: none; }
  ul li {
    display: inline; }

.container {
  display: flex;
  justify-content: center; }

a.link {
  text-decoration: none;
  color: #24717c; }
  a.link:visited {
    color: #24717c; }


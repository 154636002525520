.download-footer {
  margin: 30px 0 0 0;
  text-align: center;
  font-size: 0.85em;
  color: #2f2f2f;
  p {
    margin: 0;
  }

  &-icon {
    color: #e25555;
  }

  &-link {
    flex: 1;
    text-align: right;
    font-size: 1.5em;
    margin-bottom: 50px;
    padding-right: 10px;
  }
}

@media (min-width: $desktop-screen-min){
  .download-footer {  
    &-link {
      padding-right: 0;
    }
  }  
}
.cv {
  font-size: 1rem;
  width: 100%;
  background: $background-color;
  justify-content: flex-end;
  padding: 5px;
}

@media (min-width: $desktop-screen-min){
  .cv {
    margin: 40px 0;
    padding: 20px;
    width: $desktop-screen-min;
    -webkit-box-shadow: 0px 0px 50px -10px #101010;
    -moz-box-shadow: 0px 0px 50px -10px #101010; 
    box-shadow: 0px 0px 50px -10px #101010; 
    .section-wrapper {
      display: flex;
      & > div {
        flex: 50%;
      }
    }
  }
}